@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $base-black;
        color: $base-white;

        .left-column {
            padding: 0 1.5rem;
        }

        @media (min-width: $breakpoint-md) {
            .left-column {
                padding: 0 3.5%;
            }
        }
    }
}